module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smiith And Mould","short_name":"S&M","start_url":"/","background_color":"#07505A","display":"minimal-ui","icon":"src/assets/images/home/featureCircle.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a2610d0621f9c33e78b48e65a6ea3cfc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
